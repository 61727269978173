"use client";

import clsx from "clsx";
import { useEffect, useState } from "react";

import { WishListIcon } from "@/components/Icon/Heart/WishListIcon";
import { useWishlist } from "@/lib/contexts/wishlist-context";

export const WishlistProductIcon = ({
  product_id,
  className,
  inWishlistClassName,
}: {
  product_id: string;
  className?: string;
  inWishlistClassName?: string;
}) => {
  const { wishlist, addProduct, removeProduct, refetch } = useWishlist();
  const [inWishlist, setInWishlist] = useState<boolean>(false);

  useEffect(() => {
    if (!wishlist) {
      refetch();
    } else {
      const findProduct = wishlist.items.find((item) => item.product.id === product_id);
      setInWishlist(!!findProduct);
    }
  }, [wishlist]);

  return (
    <WishListIcon
      className={clsx(className, `h-7 w-7 min-h-7 min-w-7 stroke-[5px] transition-all duration-150 cursor-pointer`, {
        "!fill-white": inWishlist && !inWishlistClassName?.length,
        "!fill-gray-600": inWishlist && inWishlistClassName?.length,
        "stroke-white fill-transparent": !className?.length,
      })}
      onClick={inWishlist ? () => removeProduct(product_id) : () => addProduct(product_id)}
    />
  );
};
